import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Image from "gatsby-image"

const HeroContainer = styled.div`
  height: 40vh;
  overflow: hidden;
  position: relative;
`

const HeroImage = styled(Image)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

const HeroContentContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 30%;
  width: 100%;
  height: auto;
`

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  text-align: center;
`

const Hero = ({ image, children }) => {
  return (
    <HeroContainer>
      <HeroImage fluid={image.childImageSharp.fluid} />
      {children && (
        <HeroContentContainer>
          <HeroContent>{children}</HeroContent>
        </HeroContentContainer>
      )}
    </HeroContainer>
  )
}

Hero.propTypes = {
  image: PropTypes.object.isRequired,
  children: PropTypes.node,
}

export default Hero
